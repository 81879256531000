// this is the man, machine, method, material, design component
import { useEffect, useState } from 'react'
import { Switch, Select } from 'antd'
import { useGetIssues } from '../hooks/Issues'

const emptyPossibleRootCauses = {
  man: null,
  machine: null,
  method: null,
  material: null,
  design: null,
}

const getPossibleRootCauses = (issue) => {
  if (!!issue && !!issue.possible_root_causes) {
    return issue.possible_root_causes
  }
  return structuredClone(emptyPossibleRootCauses)
}

export const PossibleRootCauses = ({ issue, setIssue, readOnly }) => {
  const [possibleRootCauses, setPossibleRootCauses] = useState(
    getPossibleRootCauses(issue),
  )
  const [inputEnabled, setInputEnabled] = useState({
    man: true,
    machine: false,
    method: false,
    material: false,
    design: false,
  })
  const [options, setOptions] = useState({
    man: [],
    machine: [],
    method: [],
    material: [],
    design: [],
  })
  const { issues } = useGetIssues()

  useEffect(() => {
    if (!issues) {
      return
    }
    const opts = {
      man: [],
      machine: [],
      method: [],
      material: [],
      design: [],
    }
    issues.forEach((iss) => {
      const prc = iss['possible_root_causes']
      if (prc) {
        Object.keys(opts).forEach((key) => {
          opts[key].push(prc[key])
        })
      }
    })
    Object.keys(opts).forEach((key) => {
      const allOptions = [...new Set(opts[key])]
      opts[key] = allOptions
        .filter((opt) => !!opt)
        .map((opt) => {
          return {
            label: opt,
            value: opt,
          }
        })
    })
    setOptions(opts)
  }, [issues])

  useEffect(() => {
    setPossibleRootCauses(getPossibleRootCauses(issue))
  }, [issue])

  const [isPRCEmpty, setIsPrcEmpty] = useState(true)
  useEffect(() => {
    setIsPrcEmpty(
      Object.keys(possibleRootCauses).every((prcKey) => {
        return !possibleRootCauses[prcKey]
      }),
    )
  }, [possibleRootCauses])

  const toggleInputEnabled = (key) => {
    const newInputs = structuredClone(inputEnabled)
    newInputs[key] = !newInputs[key]
    setInputEnabled(newInputs)
  }

  const updateIssuePossibleRootCauses = (key, value) => {
    const newPossibleRootCauses = structuredClone(possibleRootCauses)
    newPossibleRootCauses[key] = value[0]
    setPossibleRootCauses(newPossibleRootCauses)
    let newIssue = {
      ...issue,
      ...{ possible_root_causes: newPossibleRootCauses },
    }
    setIssue(newIssue)
  }
  return (
    <div id="issue-possible-root-causes">
      {Object.keys(possibleRootCauses)
        .filter((key) => {
          return !readOnly || !!possibleRootCauses[key]
        })
        .map((key) => {
          return (
            <div
              className="input-and-label"
              key={`possible-root-causes-${key}`}
            >
              <span className="input-label">
                {!readOnly && (
                  <Switch
                    onClick={() => toggleInputEnabled(key)}
                    defaultChecked={inputEnabled[key]}
                    disabled={readOnly}
                  />
                )}
                {key}
              </span>
              <Select
                mode="tags"
                placeholder={
                  readOnly ? '' : 'Enter or select similar description'
                }
                value={
                  !!possibleRootCauses[key] ? [possibleRootCauses[key]] : []
                }
                disabled={readOnly || !inputEnabled[key]}
                options={options[key]}
                onChange={(value) => updateIssuePossibleRootCauses(key, value)}
                maxCount="1"
              />
            </div>
          )
        })}
      {isPRCEmpty && readOnly && (
        <span class="empty-prc-notice">No possible root causes entered.</span>
      )}
    </div>
  )
}
