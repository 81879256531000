import { useEffect, useMemo, useState } from 'react'
import { useGetIssues } from '../hooks/Issues'
import Table from '../components/Table'
import { fetchProblemLogsForIssues, linkProblemLogToIssue } from '../api/issues'
import { navigateToIncidentMgmtPage } from './IncidentMgmt'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { IssueSearch } from './IssueSearch'
import { PlantButton } from '../Reports/PlantButton'
// This is the list of issues on the right hand side of the figma when
// we don't have a defined fault/issue
// https://www.figma.com/proto/ITEOBzG70wVkwRkep3rc0B/Tracer---Ant-Design?page-id=9316%3A23734&node-id=9613-21823&node-type=frame&viewport=-64%2C334%2C0.12&t=KPS4u8Suia3eGyRO-1&scaling=contain&content-scaling=fixed&starting-point-node-id=9607%3A15334
// If you pass in issues it will just display a table of those,
// otherwise it displays all issues
// If you pass in a fault it will have the option of linking that fault to
// the issues

const IssueSelectButton = ({ issue, selectedIssue, setSelectedIssue }) => {
  return (
    <input
      type="radio"
      checked={!!selectedIssue && selectedIssue.id === issue.id}
      onChange={() => setSelectedIssue(issue)}
    />
  )
}

const IssueFirstCase = ({ issue, problemLogs }) => {
  if (!!problemLogs && problemLogs.length > 0 && !!issue) {
    problemLogs.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
    return dayjs(problemLogs[0].created_at).format('MM/DD/YYYY h:mma')
  }
  return ''
}

const EditOrAttachToIssueButton = ({
  issue,
  problemLogs,
  isAttachingCases,
  fault,
}) => {
  const attachFaultToIssue = () => {
    linkProblemLogToIssue(issue.id, fault.id).then(() => {
      const url = `${window.location.origin}/incidents`
      window.open(url, '_self')
    })
  }

  if (isAttachingCases) {
    return (
      <Button color="primary" variant="outlined" onClick={attachFaultToIssue}>
        Add Case
      </Button>
    )
  }
  if (!!problemLogs && problemLogs.length > 0) {
    const fault = problemLogs[0]
    fault['attachedIssue'] = issue
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          navigateToIncidentMgmtPage(fault)
        }}
      >
        <EditOutlined />
      </Button>
    )
  }

  return ''
}

export const IssueList = ({ fault, issues, setIssue, setFault, intl }) => {
  const { issues: allIssues, isLoading } = useGetIssues()
  const [selectedIssue, setSelectedIssue] = useState(null)
  const [problemLogsForIssues, setProblemLogsForIssues] = useState({})
  const [isAttachingCases, setIsAttachingCases] = useState(false)

  const cols = useMemo(
    () => [
      {
        Header: '',
        id: 'issue-radio-button',
        disableSortBy: true,
        accessor: (row) =>
          IssueSelectButton({ issue: row, selectedIssue, setSelectedIssue }),
      },
      {
        Header: 'Issue',
        accessor: (row) => row.id,
      },
      {
        Header: 'Description',
        accessor: (row) => row.description,
      },
      {
        Header: 'First Case',
        accessor: (row) =>
          IssueFirstCase({
            issue: row,
            problemLogs: problemLogsForIssues[row.id],
          }),
      },
      {
        Header: 'Clean point',
        accessor: (row) => {
          return row.closed_at
            ? dayjs(row.closed_at).format('MM/DD/YYYY h:mma')
            : '--'
        },
      },
      {
        Header: 'Status',
        accessor: (row) => row.status,
      },
      {
        Header: '#',
        accessor: (row) =>
          !!problemLogsForIssues[row.id]
            ? problemLogsForIssues[row.id].length
            : 0,
      },
      {
        Header: '',
        id: 'issue-edit-col',
        disableSortBy: true,
        accessor: (row) =>
          EditOrAttachToIssueButton({
            fault: fault,
            issue: row,
            problemLogs: problemLogsForIssues[row.id],
            isAttachingCases: isAttachingCases,
          }),
      },
    ],
    [selectedIssue, setSelectedIssue, problemLogsForIssues, isAttachingCases],
  )

  const [issuesToShow, setIssuesToShow] = useState([])

  const selectIssuesToShow = () => {
    if (!!issues) {
      setIssuesToShow(issues)
      setIsAttachingCases(true)
    } else if (!!fault && !!fault.relatedIssues) {
      // just in case these were passed here
      setIssuesToShow(fault.relatedIssues)
      setIsAttachingCases(true)
    } else if (!!allIssues) {
      setIssuesToShow(allIssues)
      setIsAttachingCases(false)
    }
  }

  // passed to IssueSearch
  const [searchTerm, setSearchTerm] = useState('')
  const [isUsingSearch, setIsUsingSearch] = useState(false)
  const updateIssuesToShow = (issues) => {
    if (issues === null) {
      selectIssuesToShow()
    } else {
      setIsUsingSearch(true)
      setIssuesToShow(issues)
    }
  }

  useEffect(() => {
    selectIssuesToShow()
  }, [issues, allIssues])

  useEffect(() => {
    if (!!issuesToShow && issuesToShow.length > 0) {
      const issueIds = issuesToShow
        .filter((issue) => !!issue.id)
        .map((issue) => issue.id)
      fetchProblemLogsForIssues(issueIds).then((plsForIssues) => {
        // this is to make the fault description work
        // the same as it does with a fault from the fault report
        Object.keys(plsForIssues).forEach((issue_id) => {
          const probs = structuredClone(plsForIssues[issue_id])
          plsForIssues[issue_id].forEach((prob, idx) => {
            plsForIssues[issue_id][idx]['problems'] = probs
          })
        })
        setProblemLogsForIssues(plsForIssues)
        setSelectedIssue(issuesToShow[0])
      })
    }
  }, [issuesToShow])

  useEffect(() => {
    if (!!selectedIssue) {
      const issueId = selectedIssue.id
      let probLog = null
      if (!!problemLogsForIssues[issueId]) {
        if (problemLogsForIssues[issueId].length > 0) {
          probLog = problemLogsForIssues[issueId][0]
        }
      }
      setFault(probLog)
      setIssue(selectedIssue)
    }
  }, [selectedIssue])

  return (
    <div id="issue-list">
      {isAttachingCases && (
        <div id="issue-list-header">
          <span id="num-prev-issues">
            {issuesToShow.length} Previous Issues
          </span>
          <Button
            id="issue-list-add-issue-header-btn"
            type="primary"
            variant="solid"
            onClick={() => {
              navigateToIncidentMgmtPage(fault)
            }}
          >
            Add Issue
          </Button>
        </div>
      )}
      <div id="issue-list-filter-ctrls">
        <PlantButton intl={intl} />
        <IssueSearch
          issues={issuesToShow}
          updateIssuesToShow={updateIssuesToShow}
          problemLogsForIssues={problemLogsForIssues}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      {issuesToShow.length > 0 ? (
        <Table columns={cols} data={issuesToShow} id="issue-list" />
      ) : (
        'No Issues'
      )}
    </div>
  )
}
