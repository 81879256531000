import React from 'react'
import { useDispatch } from 'react-redux'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import { injectIntl } from 'react-intl'
import { requireAuthentication } from '../helpers/authentication'
import { useLocalStorage } from 'react-use'
import { Select, DatePicker, Form, Button } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import objectSupport from 'dayjs/plugin/objectSupport'
import Table from '../components/Table'
import { useFaults } from '../hooks/Faults'
import { useUserPlant, useUserTestZones } from '../hooks/User'
import { useMakeModels } from '../hooks/MakeModels'
import { useStationInfo } from '../hooks/StationInfo'
import { useShifts } from '../hooks/Shifts'
import { openTabWithVehicleTestByResultId } from '../api/vehicle-tests-api'
import Loading from '../components/Loading'
import { setModal } from '../actions/modal-actions'
import { MODAL_SELECT_PLANT, MODAL_SELECT_TESTZONE } from '../constants'
import ExportButton from './Export'
import { groupFaults } from './utils'
import { apiGetTerminology } from '../api/terminology'
import { locale } from '../Locale'
import { Spin } from 'antd'
import { PlantButton } from './PlantButton'

import iconRedAsterisk from '../assets/images/red-asterisk.png'
import './styles.scss'
import { AddCaseButton, IssueButton, IssueStatus } from './IssueButton'

dayjs.extend(objectSupport)
dayjs.extend(utc)

const { Option } = Select
const { RangePicker } = DatePicker

const TestZoneButton = ({ intl }) => {
  const [userTestZones] = useUserTestZones()
  const dispatch = useDispatch()
  const testZoneLabel = intl.formatMessage({
    id: 'faultReport.testZoneLabel',
    defaultMessage: 'Test Zones',
  })
  return (
    <div className="plant testzone">
      {testZoneLabel}:&nbsp;
      <button onClick={() => dispatch(setModal(MODAL_SELECT_TESTZONE))}>
        {userTestZones.length > 0
          ? userTestZones.map((t) => t.description).join(', ')
          : 'select test zones'}
      </button>
    </div>
  )
}

const ReportControl = ({ intl, searchTerm, setSearchTerm, faults }) => {
  const [userPlant] = useUserPlant()

  const { isLoading: makeModelsIsLoading, makeModels: allMakeModels } =
    useMakeModels({ plantName: userPlant })
  const { isLoading: shiftsIsLoading, shifts: plantShifts } = useShifts({
    plant_name: userPlant,
  })

  const { isLoading: stationInfoIsLoading, stationInfo: allStationInfo } =
    useStationInfo({ plant_name: userPlant })

  if (stationInfoIsLoading || makeModelsIsLoading || shiftsIsLoading) {
    return <Loading useBackgroundColor={false} />
  }
  return (
    <ReportControlForm
      intl={intl}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      faults={faults}
      allStationInfo={allStationInfo}
      allMakeModels={allMakeModels}
      plantShifts={plantShifts}
    />
  )
}

function ReportControlForm({
  intl,
  searchTerm,
  setSearchTerm,
  faults,
  allStationInfo,
  allMakeModels,
  plantShifts,
}) {
  const [allDepartments, setAllDepartments] = React.useState([])
  const [allStationIds, setAllStationIds] = React.useState([])
  const [allTeams, setAllTeams] = React.useState([])
  const [allZones, setAllZones] = React.useState([])
  const [availableZones, setAvailableZones] = React.useState(allZones)
  const [availableTeams, setAvailableTeams] = React.useState(allTeams)
  const [form] = Form.useForm()
  const [, setUserTestZones] = useUserTestZones()

  React.useEffect(() => {
    if (!allStationInfo) {
      return
    }
    setAllStationIds([...new Set(allStationInfo.map((s) => s.station_id))])
    const depts = [
      ...new Set(
        allStationInfo.filter((s) => !!s.department).map((s) => s.department),
      ),
    ]
    setAllDepartments(depts)
    const teams = [
      ...new Set(allStationInfo.filter((s) => !!s.team).map((s) => s.team)),
    ]
    setAllTeams(teams)
    setAvailableTeams(teams)
    const zones = [
      ...new Set(allStationInfo.filter((s) => !!s.zone).map((s) => s.zone)),
    ]
    setAllZones(zones)
    setAvailableZones(zones)
  }, [allStationInfo])

  const todayStart = dayjs().startOf('day')
  const todayEnd = dayjs().endOf('day')

  const setTimeToDate = (date, time) => {
    return date.clone().set({
      hour: time.hour(),
      minute: time.minute(),
      second: 0,
      millisecond: 0,
    })
  }

  const getShiftDateTime = ({ period, currentShiftName, shifts }) => {
    const startDate = period[0].startOf('day')
    const endDate = period[1].endOf('day')
    const adjustedShifts = {}
    let daySwitched = false

    for (const [shiftName, hours] of Object.entries(shifts)) {
      const startHour = dayjs.utc(hours.start, 'HH:mm').local()
      const endHour = dayjs.utc(hours.end, 'HH:mm').local()
      const startAt = setTimeToDate(startDate, startHour)
      const endAt = setTimeToDate(endDate, endHour)

      // Shifts are list of start and end time that cover 24 hours.
      // For example, one shift can be in period 03:00-06:00.
      // BUT it is possible that shift starts at one day but ends at the next day.
      // In this case, its period will be something like this: 18:00-03:00.
      if (endAt < startAt) {
        // Cover case when shift starts at one day but ends at the next day.
        daySwitched = true
        endAt.add(1, 'days')
      } else if (daySwitched) {
        // Cover case if previous shift has date change.
        endAt.add(1, 'days')
        startAt.add(1, 'days')
      }

      adjustedShifts[shiftName] = {
        startAt,
        endAt,
      }
    }
    return adjustedShifts[currentShiftName]
  }

  function isAllSelected(all, selected) {
    return (
      all.length === selected.length &&
      all.every((element, index) => element === selected[index])
    )
  }

  // Shifts hours are in UTC
  const allShiftsItem = {
    name: 'All Day',
    periods: {
      start: todayStart.utc().format('HH:mm'),
      end: todayEnd.utc().format('HH:mm'),
    },
  }

  const shifts = {
    ...{ [allShiftsItem.name]: allShiftsItem.periods },
    ...plantShifts.reduce((acc, shift) => {
      return {
        ...acc,
        [shift.name]: { start: shift.starts_at, end: shift.ends_at },
      }
    }, {}),
  }
  const allMakeModelIds = allMakeModels.map((makeModel) => makeModel.id)

  const updateAvailableTeamsAndZones = ({ selectedDepartment }) => {
    if (!selectedDepartment) {
      selectedDepartment = form.getFieldValue('department')
    }
    if (!selectedDepartment) {
      if (availableTeams.length != allTeams.length) {
        setAvailableTeams(allTeams)
      }
      if (availableZones.length != allZones.length) {
        setAvailableZones(allZones)
      }
      return
    }
    const availableStations = allStationInfo.filter(
      (s) => selectedDepartment === s.department,
    )
    setAvailableZones([
      ...new Set(availableStations.filter((s) => !!s.zone).map((s) => s.zone)),
    ])
    setAvailableTeams([
      ...new Set(availableStations.filter((s) => !!s.team).map((s) => s.team)),
    ])
  }

  const updateSelectedStations = ({ selectedZones, selectedTeams }) => {
    if (!selectedTeams) {
      selectedTeams = form.getFieldValue('teams')
    }
    if (!selectedZones) {
      selectedZones = form.getFieldValue('zones')
    }

    if (
      (!selectedZones || selectedZones.length === 0) &&
      (!selectedTeams || selectedTeams.length == 0)
    ) {
      return
    }

    const inSelectedZones = (station) => {
      return (
        !selectedZones ||
        selectedZones.length === 0 ||
        selectedZones.includes(station.zone)
      )
    }

    const inSelectedTeams = (station) => {
      return (
        !selectedTeams ||
        selectedTeams.length === 0 ||
        selectedTeams.includes(station.team)
      )
    }

    const existingStations = form.getFieldValue('stationIds')
    const stationIds = [
      ...new Set(
        allStationInfo
          .filter((s) => inSelectedZones(s) && inSelectedTeams(s))
          .map((s) => s.station_id)
          .concat(existingStations),
      ),
    ]
    form.setFieldValue('stationIds', stationIds)
  }

  // clear out plant-dependent search terms
  // (zone, test zone, department, stations)
  const onPlantSwitch = () => {
    form.setFieldValue('stationIds', [])
    form.setFieldValue('department', null)
    form.setFieldValue('zones', [])
    form.setFieldValue('teams', [])
  }

  return (
    <>
      <PlantButton intl={intl} onPlantSwitch={onPlantSwitch} />
      <TestZoneButton intl={intl} />
      <div className="report-control">
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            stationIds: searchTerm.stationIds,
            makeModelIds:
              isAllSelected(allMakeModelIds, searchTerm.makeModelIds) === true
                ? []
                : searchTerm.makeModelIds,
            shift: searchTerm.shift || allShiftsItem.name,
            period: [
              (searchTerm.period.start && dayjs(searchTerm.period.start)) ||
                todayStart,
              (searchTerm.period.end && dayjs(searchTerm.period.end)) ||
                todayEnd,
            ],
            zones: searchTerm.zones,
            department: searchTerm.department,
            teams: searchTerm.teams,
            testZones: searchTerm.testZones,
          }}
          onFinish={(values) => {
            const {
              period,
              shift,
              stationIds,
              makeModelIds,
              zones,
              teams,
              department,
            } = values
            const shiftDateTime = getShiftDateTime({
              period,
              currentShiftName: shift,
              shifts,
            })
            setSearchTerm({
              start_at: shiftDateTime.startAt,
              end_at: shiftDateTime.endAt,
              stationIds: stationIds,
              makeModelIds:
                makeModelIds.length === 0 ? allMakeModelIds : makeModelIds,
              zones: zones,
              teams: teams,
              department: department,
              shift,
              period: { start: period[0], end: period[1] },
            })
          }}
        >
          <Form.Item label="Department" name="department">
            <Select
              name="department"
              allowClear
              placeholder={intl.formatMessage({
                id: 'faultReport.filters.departments.placeholder',
                defaultMessage: 'All Departments',
              })}
              onChange={(selectedDepartment) => {
                updateAvailableTeamsAndZones({ selectedDepartment })
              }}
            >
              {allDepartments.map((dept) => (
                <Option key={dept} value={dept}>
                  {dept}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Zones" name="zones">
            <Select
              name="zones"
              mode="multiple"
              allowClear
              placeholder={intl.formatMessage({
                id: 'faultReport.filters.zones.placeholder',
                defaultMessage: 'All Zones',
              })}
              onChange={(selectedZones) => {
                updateSelectedStations({ selectedZones })
              }}
            >
              {availableZones.map((zone) => (
                <Option key={zone} value={zone}>
                  {zone}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Teams" name="teams">
            <Select
              name="teams"
              mode="multiple"
              allowClear
              placeholder={intl.formatMessage({
                id: 'faultReport.filters.teams.placeholder',
                defaultMessage: 'All Teams',
              })}
              onChange={(selectedTeams) => {
                updateSelectedStations({ selectedTeams })
              }}
            >
              {availableTeams.map((team) => (
                <Option key={team} value={team}>
                  {team}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Stations" name="stationIds">
            <Select
              name="stationIds"
              mode="multiple"
              allowClear
              placeholder={intl.formatMessage({
                id: 'faultReport.filters.stations.placeholder',
              })}
            >
              {allStationIds.map((station) => (
                <Option key={station} value={station}>
                  {station}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Vehicles" name="makeModelIds">
            <Select
              name="makeModelIds"
              mode="multiple"
              allowClear
              placeholder={intl.formatMessage({
                id: 'faultReport.filters.vehicles.placeholder',
              })}
            >
              {allMakeModels.map((makeModel) => (
                <Option key={makeModel.id} value={makeModel.id}>
                  {`${makeModel.make} - ${makeModel.model} - ${makeModel.year} - ${makeModel.phase}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/*
          Workaround to get `period` field value.
          See https://github.com/ant-design/ant-design/issues/22060#issuecomment-1338763903
        */}
          <Form.Item dependencies={['period']}>
            {({ getFieldValue }) => (
              <Form.Item name="shift" label="Time interval">
                <Select
                  name="shift"
                  disabled={
                    !getFieldValue('period')[0].diff(
                      getFieldValue('period')[1],
                      'days',
                    ) == 0
                  }
                  placeholder="Please select interval"
                  data-cy="report-shift"
                >
                  {Object.keys(shifts).map((shift) => (
                    <Option key={shift} value={shift}>
                      {ShiftLabel(intl, shift)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label="Period" name="period" layout="horizontal">
            <RangePicker
              name="period"
              data-cy="report-date-range"
              allowClear={false}
              onChange={(period) => {
                if (!period[0].isSame(period[1])) {
                  form.setFieldValue('shift', allShiftsItem.name)
                }
              }}
              presets={[
                {
                  label: 'Today',
                  value: [todayStart.local(), todayEnd.local()],
                },
              ]}
            />
          </Form.Item>
          <div className="form-buttons">
            <Button htmlType="submit" type="primary">
              {intl.formatMessage({ id: 'forms.submit' })}
            </Button>
            <ExportButton faults={faults} intl={intl} />
          </div>
        </Form>
      </div>
    </>
  )
}

function ShiftLabel(intl, shift) {
  let shiftTokens = shift.split(' ')
  if (shiftTokens.length === 2) {
    if (!isNaN(shiftTokens[1])) {
      return (
        intl.formatMessage({ id: 'faultReport.filters.shifts.label' }) +
        ' ' +
        shiftTokens[1]
      )
    } else {
      return intl.formatMessage({
        id: 'faultReport.filters.shifts.supersetLabel',
      })
    }
  }
  return shift
}

function FailureTimeCheckBox({ setPeriods, periods, row }) {
  const checked = periods.some(
    (period) => period.day === row.day && period.hour === row.hour,
  )

  const removePeriod = () => {
    setPeriods(
      periods.filter(
        (period) => !(period.day === row.day && period.hour === row.hour),
      ),
    )
  }
  const addPeriod = () => {
    setPeriods([...periods, { day: row.day, hour: row.hour }])
  }

  return (
    <label className="time-check-box">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => (checked ? removePeriod() : addPeriod())}
      />
      {` ${row.hour}`}
    </label>
  )
}

function FaultRadioButton({ selectedFault, setFault, row }) {
  return (
    <label className="fault-radio-button">
      <input
        type="radio"
        checked={selectedFault.key === row.key}
        onChange={() => setFault(row)}
      />
    </label>
  )
}

function StationAndAlertIcon({ row }) {
  if (row.station_alert === 'send') {
    return (
      <div>
        <img style={{ width: '10px' }} src={iconRedAsterisk} alt="asterisk" />
        <span style={{ marginLeft: '0.5rem' }}>{row.station}</span>
      </div>
    )
  }
  return row.station
}

function FaultChart({ data }) {
  const countFailures = (data) => {
    return [
      {
        component: data.component.length,
        device: data.device.length,
        harness: data.harness.length,
        other: data.other.length,
      },
    ]
  }

  const labelFormatter = (value) => (value > 0 ? value : null)
  const labelStyle = { fill: 'white' }

  return (
    <div className="fault-chart">
      <ResponsiveContainer>
        <BarChart data={countFailures(data)} layout="vertical">
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          <Bar
            stackId="stack"
            dataKey="component"
            fill="#0189E9"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="component"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
          <Bar
            stackId="stack"
            dataKey="harness"
            fill="#FFC000"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="harness"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
          <Bar
            stackId="stack"
            dataKey="other"
            fill="#D74053"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="other"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
          <Bar
            stackId="stack"
            dataKey="device"
            fill="#9ba52a"
            isAnimationActive={false}
          >
            <LabelList
              dataKey="device"
              style={labelStyle}
              formatter={labelFormatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

const countFailures = (data) => {
  return Object.values(data).reduce(
    (dayFaultsCount, hourFaults) => {
      const hourFaultsCount = Object.values(hourFaults).reduce(
        (acc, fault) => {
          acc.component += fault.component.length
          acc.device += fault.device.length
          acc.harness += fault.harness.length
          acc.other += fault.other.length
          return acc
        },
        {
          component: 0,
          device: 0,
          other: 0,
          harness: 0,
        },
      )
      dayFaultsCount.component += hourFaultsCount.component
      dayFaultsCount.device += hourFaultsCount.device
      dayFaultsCount.harness += hourFaultsCount.harness
      dayFaultsCount.other += hourFaultsCount.other
      return dayFaultsCount
    },
    {
      component: 0,
      device: 0,
      other: 0,
      harness: 0,
    },
  )
}
function FailuresOverview({ intl, periods, setPeriods, data }) {
  const failuresCount = countFailures(data)

  if (Object.keys(data).length == 0) {
    return <div className="no-faults">No Faults found</div>
  }

  return (
    <div id="failures-overview">
      <div className="failures-count">
        <div className="count-type">
          {intl.formatMessage({ id: 'generic.allFailures' })}{' '}
          {Object.values(failuresCount).reduce(
            (partialSum, a) => partialSum + a,
            0,
          )}
        </div>
        <div className="count-type">
          <div className="component-dot"></div>
          {intl.formatMessage({ id: 'generic.component' })}{' '}
          {failuresCount.component}
        </div>
        <div className="count-type">
          <div className="harness-dot"></div>
          {intl.formatMessage({ id: 'generic.harness' })}{' '}
          {failuresCount.harness}
        </div>
        <div className="count-type">
          <div className="other-dot"></div>
          {intl.formatMessage({ id: 'generic.generic' })} {failuresCount.other}
        </div>
        <div className="count-type">
          <div className="device-dot"></div>
          {intl.formatMessage({ id: 'generic.device' })} {failuresCount.device}
        </div>
      </div>
      {Object.keys(data).map((day) => {
        return (
          <div className="failures-day" key={day}>
            <div className="failure-date">{day}</div>
            {Object.keys(data[day]).map((hour) => {
              return (
                <div className="failure-hour" key={`${day}-${hour}`}>
                  <FailureTimeCheckBox
                    setPeriods={setPeriods}
                    periods={periods}
                    row={data[day][hour]}
                  />
                  <FaultChart data={data[day][hour]} />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

function countComponentFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.component_id}::${fault.station}::${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'component',
        id: fault.id,
        component_id: fault.component_id,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        station: fault.station,
        station_alert: fault.station_alert,
        reason: fault.reason,
        component_description: fault.component_description,
        translated_description: fault.translated_description,
        problems: [],
        key,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      type: 'component',
      vin: fault.vin,
      id: fault.id,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      component_id: fault.component_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function countDeviceFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.device_name}::${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'device',
        id: fault.id,
        device_name: fault.device_name,
        reason: fault.reason,
        description: fault.description,
        translated_description: fault.translated_description,
        problems: [],
        key,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      vin: fault.vin,
      id: fault.id,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function countHarnessFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.harness}::${fault.description}::${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'harness',
        id: fault.id,
        harness: fault.harness,
        description: fault.description,
        translated_description: fault.translated_description,
        reason: fault.reason,
        problems: [],
        key,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      vin: fault.vin,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      id: fault.id,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function countOtherFaults(faults) {
  return faults.reduce((counted, fault) => {
    const key = `${fault.reason}`
    if (!counted.get(key)) {
      counted.set(key, {
        type: 'other',
        reason: fault.reason,
        id: fault.id,
        problems: [],
        key,
        attachedIssue: fault.attachedIssue,
        relatedIssues: fault.relatedIssues,
        test_zone: fault.hardware_location_id,
        short_code: fault.short_code,
        problem_code: fault.problem_code,
        fault_key: fault.fault_key,
        station_id: fault.station_id,
      })
    }
    counted.get(key).problems.push({
      vin: fault.vin,
      vehicle_test_result_id: fault.vehicle_test_result_id,
      created_at: fault.created_at,
      note: fault.note,
      created_by: fault.created_by,
      attachedIssue: fault.attachedIssue,
      relatedIssues: fault.relatedIssues,
      id: fault.id,
      test_zone: fault.hardware_location_id,
      short_code: fault.short_code,
      problem_code: fault.problem_code,
      fault_key: fault.fault_key,
      station_id: fault.station_id,
    })
    return counted
  }, new Map())
}

function ComponentFaults({ faults, selectedFault, setFault, intl }) {
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'failure-radio-button',
        disableSortBy: true,
        accessor: (row) => FaultRadioButton({ selectedFault, setFault, row }),
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.components',
        }),
        accessor: (row) =>
          `${row.component_id}\n${row.translated_description ? row.translated_description : row.component_description}`,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.station',
        }),
        accessor: (row) => StationAndAlertIcon({ row }),
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.reason',
        }),
        accessor: (row) => row.reason,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.componentFaultsHeaders.count',
        }),
        accessor: (row) => row.problems.length,
      },
      {
        Header: 'Status', // TODO: add translation
        accessor: (row) => IssueStatus({ fault: row }),
      },
      {
        Header: '',
        id: 'issue-button-col',
        accessor: (row) => IssueButton({ fault: row }),
        disableSortBy: true,
      },
    ],
    [selectedFault, setFault],
  )
  return faults.length > 0 ? (
    <Table columns={columns} data={faults} id="component-faults" />
  ) : (
    ''
  )
}

function OtherFaults({ faults, selectedFault, setFault, intl }) {
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'failure-radio-button',
        disableSortBy: true,
        accessor: (row) => FaultRadioButton({ selectedFault, setFault, row }),
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.otherFaultsHeaders.generic',
        }),
        accessor: (row) => row.reason,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.otherFaultsHeaders.count',
        }),
        accessor: (row) => row.problems.length,
      },
    ],
    [selectedFault, setFault],
  )
  return faults.length > 0 ? (
    <Table columns={columns} data={faults} id="other-faults" />
  ) : (
    ''
  )
}

function HarnessFaults({ faults, selectedFault, setFault, intl }) {
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'failure-radio-button',
        disableSortBy: true,
        accessor: (row) => FaultRadioButton({ selectedFault, setFault, row }),
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.harness',
        }),
        accessor: (row) =>
          `${row.harness}\n${row.translated_description ? row.translated_description : row.description}`,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.reason',
        }),
        accessor: (row) => row.reason,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.description',
        }),
        accessor: (row) => row.description,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.harnessFaultsHeaders.count',
        }),
        accessor: (row) => row.problems.length,
      },
      {
        Header: 'Status', // TODO: add translation
        accessor: (row) => IssueStatus({ fault: row }),
      },
      {
        Header: '',
        id: 'issue-button-col',
        accessor: (row) => IssueButton({ fault: row }),
        disableSortBy: true,
      },
    ],
    [selectedFault, setFault],
  )
  return faults.length > 0 ? (
    <Table columns={columns} data={faults} id="harness-faults" />
  ) : (
    ''
  )
}

function DeviceFaults({ faults, selectedFault, setFault, intl }) {
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        id: 'failure-radio-button',
        disableSortBy: true,
        accessor: (row) => FaultRadioButton({ selectedFault, setFault, row }),
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.device',
        }),
        accessor: (row) =>
          `${row.device_name}\n${row.translated_description ? row.translated_description : row.description}`,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.reason',
        }),
        accessor: (row) => row.reason,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.description',
        }),
        accessor: (row) => row.description,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.deviceFaultsHeaders.count',
        }),
        accessor: (row) => row.problems.length,
      },
      {
        Header: 'Status', // TODO: add translation
        accessor: (row) => IssueStatus({ fault: row }),
      },
      {
        Header: '',
        id: 'issue-button-col',
        accessor: (row) => IssueButton({ fault: row }),
        disableSortBy: true,
      },
    ],
    [selectedFault, setFault],
  )
  return faults.length > 0 ? (
    <Table columns={columns} data={faults} id="device-faults" />
  ) : (
    ''
  )
}

const VinURL = ({ vin, vehicleTestResultId, componentId }) => {
  return (
    <a
      onClick={(event) => {
        event.preventDefault()
        openTabWithVehicleTestByResultId({ vehicleTestResultId, componentId })
      }}
    >
      {vin}
    </a>
  )
}

function VehicleTestTable({ data, intl, parentFault, setFault }) {
  const getIssue = () => {
    return parentFault.attachedIssue
  }

  const columns = React.useMemo(
    () => [
      {
        Header: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.vin',
        }),
        accessor: (row) => (
          <VinURL
            vin={row.vin}
            vehicleTestResultId={row.vehicle_test_result_id}
            componentId={row.type === 'component' ? row.component_id : null}
          />
        ),
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.timestamp',
        }),
        accessor: (row) => {
          return (
            <div className="test-details-col">
              <span className="test-zone-lbl">{row.test_zone}</span>
              <span className="created-at-lbl">
                {dayjs(row.created_at).format('MM/DD/YYYY h:mma')}
              </span>
            </div>
          )
        },
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.notes',
        }),
        accessor: (row) => row.note,
      },
      {
        Header: intl.formatMessage({
          id: 'faultReport.vehicleTestTableHeaders.userId',
        }),
        accessor: (row) => row.created_by,
      },
      {
        Header: '',
        id: 'add-case-btn-col',
        accessor: (row) =>
          AddCaseButton({
            parentFault,
            fault: row,
            issue: getIssue(),
            setFault,
          }),
      },
    ],
    [],
  )

  return <Table columns={columns} data={data} id="report-vehicle-tests" />
}

const SelectedFault = ({ fault, intl }) => {
  let text = ''
  if (fault.type === 'component') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.component' })} "${fault.component_id} - ${fault.component_description} - ${fault.reason}"`
  } else if (fault.type === 'harness') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.harness' })} "${fault.harness} - ${fault.reason}"`
  } else if (fault.type === 'device') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.device' })} "${fault.device_name} - ${fault.reason}"`
  } else if (fault.type === 'other') {
    text = `${intl.formatMessage({ id: 'faultReport.selectedFaultText.other' })} ${fault.reason}`
  }

  return (
    <div>
      {intl.formatMessage({ id: 'faultReport.selectedFaultText.selected' })}:{' '}
      {text}
    </div>
  )
}

const SelectedPeriodsInfo = ({ faultsCount, intl }) => {
  const selectedText =
    intl.formatMessage({ id: 'faultReport.selectedPeriodsInfo.selected' }) +
    ':' +
    faultsCount +
    ' ' +
    intl.formatMessage({ id: 'faultReport.selectedPeriodsInfo.faults' })
  const asteriskLegend = intl.formatMessage({
    id: 'faultReport.selectedPeriodsInfo.asterisk',
  })
  return (
    <div id="selected-periods-info">
      <span>{selectedText}.</span>
      <span>
        <img style={{ width: '10px' }} src={iconRedAsterisk} alt="asterisk" />
        {asteriskLegend}
      </span>
    </div>
  )
}

const GenerateTerminology = (faults) =>
  new Promise((resolve) => {
    let requestBody = {}
    let faultObject = {}

    faults.forEach((fault) => {
      switch (fault.type) {
        case 'component':
          faultObject[`${fault.component_id}`] = fault
          if (requestBody[fault.vin]) {
            requestBody[fault.vin].push(fault.component_id)
          } else {
            requestBody[fault.vin] = [fault.component_id]
          }
          break
        case 'device':
          faultObject[`${fault.device_name}`] = fault
          if (requestBody[fault.vin]) {
            requestBody[fault.vin].push(fault.device_name)
          } else {
            requestBody[fault.vin] = [fault.device_name]
          }
          break
        case 'harness':
          faultObject[`${fault.harness}`] = fault
          if (requestBody[fault.vin]) {
            requestBody[fault.vin].push(fault.harness)
          } else {
            requestBody[fault.vin] = [fault.harness]
          }
          break
      }
    })

    const promiseArray = []
    for (const [key, value] of Object.entries(requestBody)) {
      promiseArray.push(
        apiGetTerminology({ vin: key, codes: value, language: locale }),
      )
    }

    Promise.allSettled(promiseArray).then((results) => {
      results.forEach((resultEntry) => {
        if (resultEntry.status == 'fulfilled') {
          Object.values(resultEntry.value).forEach((result) => {
            switch (faultObject[result.code].type) {
              case 'component':
                if (faultObject[result.code].component_id == result.code)
                  faultObject[result.code]['translated_description'] =
                    result.description
                break
              case 'device':
                if (faultObject[result.code].device_name == result.code)
                  faultObject[result.code]['translated_description'] =
                    result.description
                break
              case 'harness':
                if (faultObject[result.code].harness == result.code)
                  faultObject[result.code]['translated_description'] =
                    result.description
                break
              default:
                break
            }
          })
          resolve(Object.values(faultObject))
        }
      })
    })
  })

function Faults({ report, periods, intl }) {
  const [selectedFault, setFault] = React.useState('')

  const [componentFaults, updateComponentFaults] = React.useState([])
  const [harnessFaults, updateHarnessFaults] = React.useState([])
  const [otherFaults, updateOtherFaults] = React.useState([])
  const [deviceFaults, updateDeviceFaults] = React.useState([])
  const [isLoadingTerminology, updateLoadingStatus] = React.useState(true)

  let faults = periods.reduce(
    (acc, period) => {
      acc.component.push(...report[period.day][period.hour].component)
      acc.harness.push(...report[period.day][period.hour].harness)
      acc.other.push(...report[period.day][period.hour].other)
      acc.device.push(...report[period.day][period.hour].device)
      return acc
    },
    { component: [], harness: [], other: [], device: [] },
  )

  React.useEffect(() => {
    setFault('')
    updateLoadingStatus(true)
    GenerateTerminology([
      ...faults.component,
      ...faults.device,
      ...faults.harness,
    ]).then((results) => {
      const updatedFaultList = {
        component: [],
        harness: [],
        other: faults.other,
        device: [],
      }
      results.forEach((result) => {
        switch (result.type) {
          case 'component':
            updatedFaultList.component.push(result)
            break
          case 'device':
            updatedFaultList.device.push(result)
            break
          case 'harness':
            updatedFaultList.harness.push(result)
            break
        }
      })
      updateComponentFaults([
        ...countComponentFaults(updatedFaultList.component).values(),
      ])
      updateHarnessFaults([
        ...countHarnessFaults(updatedFaultList.harness).values(),
      ])
      updateOtherFaults([...countOtherFaults(updatedFaultList.other).values()])
      updateDeviceFaults([
        ...countDeviceFaults(updatedFaultList.device).values(),
      ])
      updateLoadingStatus(false)
    })
  }, [periods])

  return (
    <>
      {isLoadingTerminology && <Spin size="large" />}
      {isLoadingTerminology || (
        <>
          <SelectedPeriodsInfo
            faultsCount={
              componentFaults.length +
              harnessFaults.length +
              otherFaults.length +
              deviceFaults.length
            }
            intl={intl}
          />
          <ComponentFaults
            faults={componentFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
          <HarnessFaults
            faults={harnessFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
          <OtherFaults
            faults={otherFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
          <DeviceFaults
            faults={deviceFaults}
            selectedFault={selectedFault}
            setFault={setFault}
            intl={intl}
          />
          {selectedFault && (
            <div className="selected-fault">
              <SelectedFault fault={selectedFault} intl={intl} />
              <VehicleTestTable
                setFault={setFault}
                parentFault={selectedFault}
                data={selectedFault.problems}
                intl={intl}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

function ReportsPage({ intl }) {
  const [searchTerm, setSearchTerm] = useLocalStorage('reports-query', {
    stationIds: [],
    makeModelIds: [],
    zones: [],
    teams: [],
    department: null,
    start_at: null,
    end_at: null,
    shift: null,
    period: { start: null, end: null },
  })

  let { makeModelIds, stationIds, start_at, end_at, zones, teams, department } =
    searchTerm
  const [station_alerts] = useLocalStorage('station-alert', 'send,skip')
  const [plants] = useUserPlant()
  const [testZones] = useUserTestZones()
  const { isSuccess, isLoading, isFetching, faults } = useFaults({
    makeModelIds,
    stationIds,
    start_at,
    end_at,
    station_alerts,
    plants,
    zones,
    teams,
    department,
    testZones: !!testZones ? testZones.map((tz) => tz.specifier) : [],
  })
  const [periods, setPeriods] = React.useState([])
  const [report, setReport] = React.useState({})

  React.useEffect(() => {
    if (!faults) return
    setReport(groupFaults(faults))
    return () => setPeriods([])
  }, [faults])

  React.useEffect(() => {
    if (!report) return
    setPeriods(getAllPeriods(report))
  }, [report])

  const getAllPeriods = (report) => {
    const allPeriods = []
    for (const [day, hours] of Object.entries(report)) {
      for (const hour of Object.keys(hours)) {
        allPeriods.push({ day, hour })
      }
    }
    return allPeriods
  }

  return (
    <div id="reports-page">
      <div className="left">
        <ReportControl
          intl={intl}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          faults={faults}
        />
        {(isLoading || isFetching) && <Loading useBackgroundColor={false} />}
        {isSuccess && (
          <FailuresOverview
            intl={intl}
            periods={periods}
            setPeriods={setPeriods}
            data={report}
          />
        )}
      </div>
      <div className="right">
        {periods?.length > 0 && Object.keys(report).length > 0 && (
          <Faults report={report} periods={periods} intl={intl} />
        )}
      </div>
    </div>
  )
}

export default requireAuthentication(injectIntl(ReportsPage))
